@use '../../styles/common';

.eula {
	@include common.center-flex;
	padding: 0.5em;

	.eula-header {
		max-width: 30em;
		overflow: hidden;
		padding-bottom: 1em;

		img {
			object-fit: contain;
			width: 100%;
			height: 100%;
		}
	}
}

.policyDetails {
	padding: 0.5em;

	overflow: auto;
	overflow-x: hidden;

	background: common.$theme-textbox-background;
	color: common.$theme-textbox-text;

	user-select: text;

	h2, h3, h4 {
		margin-top: 1em;
	}
}
