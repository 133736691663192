@use '../../../styles/common';

.Form {
	@include common.flex(column);
	align-items: center;
	width: 100%;
	margin: 1rem 0;

	h1 {
		font-size: 1.8rem;
		margin-bottom: 1em;
		text-align: center;
	}

	p {
		font-size: 0.9em;
		margin: 0 0 2em;
	}

	.FormField {
		@include common.flex(column);
		gap: 0.25em;
		width: 100%;
	}

	&.dirty {
		input:invalid {
			border-color: common.$theme-input-border-error;
			outline-color: common.$theme-input-border-error;
		}
	}

	.FormErrorMessage {
		font-size: 0.75em;
		color: common.$theme-form-validationmessage-text;
		font-weight: 600;
	}

	.FormFieldError {
		font-size: 0.85em;
		color: common.$theme-form-validationmessage-text;
		margin-bottom: 0.8em;

		&.empty:before {
			content: '\200b';
		}
	}

	.FormLink {
		font-size: 0.95rem;
		margin-top: 1em;
	}

	.warning-box {
		margin: 0 0 1.5em 0;
	}
}
