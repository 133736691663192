@use 'styles/typography';
@use 'styles/forms';
@use 'styles/common';

* {
	box-sizing: border-box;
}

html, body {
	height: 100%;
	margin: 0;
	background: common.$theme-normal-background;
	color: common.$theme-normal-text;
}

body {
	--pandora-accent-color: #3daee9;
	accent-color: var(--pandora-accent-color);

	scrollbar-color: common.$theme-scrollbar-thumb common.$theme-scrollbar-track;
}

#pandora-root, #editor-root {
	@include common.flex(column);
	margin: 0;
	height: 100%;
	background: common.$theme-normal-background;
	color: common.$theme-normal-text;
	user-select: none;
}

div.main-container {
	position: relative;
	background: common.$theme-normal-background;
	color: common.$theme-normal-text;
	flex: 1;
	overflow: hidden;

	> .Toastify {
		position: relative;
		--toastify-font-family: $theme-font-family-variable;
	}
}

// Do NOT put this into .main-container; it needs to have a low specificity to be overridable by subscreens
.main {
	@include common.flex(column);
	width: 100%;
	height: 100%;
	overflow: auto;

	& > * {
		flex: 1;
	}

	& > footer {
		flex: none;
		align-self: stretch;
		height: 1.5em;
		padding: 0.2em;
		font-style: italic;
		background: common.$theme-footer-background;
		color: common.$theme-footer-text;
		user-select: text;
	}
}
