@use '../../styles/common';
@use 'sass:map';

$context-menu-themes: (
	opaque: (
		background: common.$theme-contextmenu-opaque-background,
		border: common.$theme-contextmenu-opaque-border,
	),
);

.context-menu {
	position: fixed;
	top: 0;
	left: 0;
	background: common.$theme-contextmenu-default-background;
	color: common.$theme-contextmenu-default-text;
	z-index: 9999;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	border: 0.15em solid common.$theme-contextmenu-default-border;
	border-radius: 0.2em;
	padding: 0.3em;
	gap: 0.3em;
	user-select: none;
	isolation: isolate;
	max-height: 100vh;

	hr {
		width: 90%;
		margin: 0;
		align-self: center;
	}

	span, button {
		@include common.center-flex;
		margin: 0;
		padding: 0.2em 0.5em;
	}

	button {
		color: common.$theme-contextmenu-button-text;
		background: transparent;
		padding: 0.5em 0.8em;

		&:hover {
			background: common.$theme-contextmenu-button-hover-background;
		}

		&.withIcon {
			padding: 0.5em;
			gap: 0.5em;

			>img {
				width: 1.8em;
				height: 1.8em;
				object-fit: contain;
			}

			>span {
				flex: 1;
				padding: 0;
				justify-content: start;
			}
		}
	}

	@each $theme-name, $theme-data in $context-menu-themes {
		&.#{$theme-name} {
			background: map.get($theme-data, "background");
			border-color: map.get($theme-data, "border");
		}
	}
}
