@use '../../styles/common';

.dialog {
	background: common.$theme-dialog-overlay;
	position: fixed;
	inset: 0;
	z-index: 10000;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	isolation: isolate;
	overflow: hidden;

	.dialog-content {
		max-width: calc(100% - 1em);
		max-height: calc(100% - 1em);

		padding: 1em;
		border: 1px solid common.$theme-dialog-border;
		border-radius: 1em;
		background: common.$theme-dialog-background;
		color: common.$theme-dialog-text;
	}

	&.top {
		justify-content: start;

		.dialog-content {
			margin-top: 4em;
		}
	}
}

.overlay-bounding-box {
	position: absolute;
	inset: 0;
	margin: 0;
	overflow: hidden;

	pointer-events: none;

	>* {
		pointer-events: auto;
	}

	&.modal {
		background: common.$theme-dialog-overlay;
		pointer-events: auto;
		z-index: 1000;
	}
}

.dialog-draggable {
	z-index: 1000;
	// Do NOT hide overflow for the dialog itself - otherwise drag handles break
	min-width: 8em;
	min-height: 5em;

	border: 1px solid common.$theme-dialog-border;
	border-radius: 1em;
	background: common.$theme-dialog-background;
	color: common.$theme-dialog-text;
	display: flex !important; // Overrides value from library
	flex-flow: column;
	isolation: isolate;

	>header {
		@include common.center-flex;
		overflow: hidden;
		padding: 0.1em 0.5em;
		border-radius: 1em 1em 0 0; // Needs to match the dialog
		background: common.$theme-dialog-header;
		position: relative;
		min-height: 1.5em;
		gap: 1em;

		.dialog-title {
			padding: 0.25em 0.5em;
		}

		.dialog-close, .dialog-shade {
			@include common.center-flex;
			width: 1.5em;
			height: 1.5em;
			cursor: pointer;
			border-radius: 50%;
		}

		.dialog-close {
			&:hover {
				background: common.$theme-dialog-button-close-hover-background;
				color: common.$theme-dialog-button-hover-text;
			}
		}

		.dialog-shade {
			&:hover {
				background: common.$theme-dialog-button-shade-hover-background;
				color: common.$theme-dialog-button-hover-text;
			}
		}
	}

	>.dialog-header {
		display: flex;

		>.drag-handle{
			flex: 12;
			cursor: move;
		}
	}

	>.dialog-content {
		padding: 1em;
		display: flex;
		flex-flow: column;
		flex: 1;
		overflow: auto;
	}

	&.shaded {
		// Override manual sizing to allow dialog to shrink
		height: auto !important;
		min-height: unset !important;
		overflow: hidden;

		>.dialog-content, .resize-handle-wrapper {
			display: none;
		}
	}

	&.dialogHighlight {
		outline: solid 3px common.$theme-dialog-border-highlight;
	}
}

.dialog-confirm {
	max-width: min(95vw, 50em);
}
