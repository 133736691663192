@use '../../../styles/common';

$header-height: 2em;

.tab-container {
	@include common.flex(column);
	position: relative;

	>.header {
		display: flex;
		width: 100%;
		margin: 0;
		padding: 0.25em 0.45em 0 0.45em;

		.tab {
			@include common.center-flex;
			padding: 0.25em 0.75em 0.5em 0.75em;
			border: solid transparent;
			border-width: 0.25em 0.25em 0 0.25em;
			margin: 0 -0.2em;
			z-index: 0;
			flex: 1;

			background: common.$theme-tablist-tab-normal-background;
			color: common.$theme-tablist-tab-normal-text;
			border-radius: 1.2em 1.2em 0em 0em;

			cursor: pointer;
			transition: background-color 0.25s ease-in-out;

			&.active {
				background: common.$theme-tablist-tab-selected-background;
				color: common.$theme-tablist-tab-selected-text;
				z-index: 2;

				&:focus-visible {
					border-color: common.$theme-tablist-tab-selected-visiblefocus-outline;
					z-index: 1;
				}
			}

			&:hover:not(.active) {
				background: common.$theme-tablist-tab-hover-background;
				color: common.$theme-tablist-tab-hover-text;
				z-index: 1;
			}

			&:focus-visible:not(.active) {
				border-color: common.$theme-tablist-tab-normal-visiblefocus-outline;
				z-index: 1;
			}

			&.slim {
				flex: 0;
				white-space: nowrap;
			}

			&.collapse {
				flex: 0;
				white-space: nowrap;
				padding: 0.25em 0.5em 0.5em 0.5em;
			}
		}

		&.collapsed {
			display: none;
		}
	}

	&.tab-position-top {
		>.header {
			overflow-x: auto;
		}
	}

	&.tab-position-top.allow-wrap {
		>.header {
			overflow-x: hidden;
			flex-flow: row wrap-reverse;
			justify-content: flex-end;
			row-gap: max(2px, 0.1em);
		}
	}

	&.tab-position-left {
		flex-flow: row;

		>.header {
			overflow-y: auto;
			flex-flow: column;
			width: unset;
			height: 100%;
			padding: 0.35em 0 0.35em 0.25em;

			.tab {
				flex: unset;
				padding: 0.25em 1em 0.25em 0.75em;
				justify-content: flex-end;
				border-width: 0.25em 0 0.25em 0.25em;
				border-radius: 1.2em 0em 0em 1.2em;
				margin: -0.1em 0;
			}
		}
	}

	>.tab-container-collapsed {
		position: absolute;
		background: common.$theme-tablist-tab-normal-background;
		color: common.$theme-tablist-tab-normal-text;
		top: 0;
		right: 0;
		padding: 0.25em;
		border: solid transparent;
		border-width: 0.25em;
		border-radius: 0 0 0 0.75em;
		z-index: 1;

		&:hover {
			background: common.$theme-tablist-tab-hover-background;
			color: common.$theme-tablist-tab-hover-text;
			z-index: 1;
		}

		&:focus-visible {
			border-color: common.$theme-tablist-tab-normal-visiblefocus-outline;
			z-index: 1;
		}
	}
}
