@use '../../styles/common';

.accountContacts {
	height: 100%;
	width: 100%;
	min-width: fit-content;

	.tab-container {
		height: 100%;

		>div {
			border-top: max(2px, 0.1em) solid common.$theme-separator;

			> .scrollable-vertical {
				padding: 0.1em 0.2em;
			}
		}
	}

	.friend {
		.status .indicator {
			font-size: x-large;
		}

		&.online {
			.status .indicator {
				color: common.$theme-contacts-status-online;
			}
		}

		&.offline {
			.status {
				font-style: italic;

				.indicator {
					color: common.$theme-contacts-status-offline;
					font-style: normal;
				}
			}
		}
	}
}
