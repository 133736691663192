@use '../../../styles/common';

.spaceJoin {
	max-width: calc(100% - 1em);
	max-height: calc(100% - 1em);

	padding: 1em;
	border: 1px solid common.$theme-dialog-border;
	border-radius: 1em;
	background: common.$theme-dialog-background;
	color: common.$theme-dialog-text;

	overflow: hidden;
}

.spaceInvite {
	display: block flex;
	flex-flow: row;
	width: calc(100% - 1em);
	margin: 0.5em;
	padding: 0.75em;
	background: common.$theme-chat-embed-normal-background;
	color: common.$theme-chat-embed-normal-text;
	font-weight: bold;
	border: 0.25em solid transparent;

	&.active {
		&:hover {
			cursor: pointer;
			background: common.$theme-chat-embed-hover-background;
			color: common.$theme-chat-embed-hover-text;
		}

		&:focus-visible {
			border-color: common.$theme-chat-embed-visiblefocus-outline;
		}
	}
}
