@use '../../styles/common';

.LeaveDialog {
	fieldset {
		display: flex;
		flex-flow: column;
		width: min(30em, 80vw);
		user-select: text;
		border-color: common.$theme-separator;

		.unimportant {
			color: common.$theme-normal-text-dim;
		}

		button {
			margin-top: 1em;
			margin-left: auto;
			width: 14em;
			user-select: none;
		}
	}
}
