@use 'constants';

//#region Flexbox

@mixin flex($direction: row, $wrap: nowrap) {
	display: flex;
	flex-flow: $direction $wrap;
}

//#endregion

//#region Positioning

@mixin center-common {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

@mixin center-absolute {
	position: absolute;
	@include center-common;
}

@mixin center-fixed {
	position: fixed;
	@include center-common;
}

@mixin center-flex($direction: row, $wrap: wrap) {
	@include flex($direction, $wrap);
	justify-content: center;
	align-items: center;
}

//#endregion

//#region Misc

@mixin corner-border($thickness: 1px, $color: black, $width: 0.5em, $background: inherit) {
	border: $thickness solid transparent;
	background:
		conic-gradient(from 90deg  at top    $thickness left  $thickness, #0000 90deg, $color 0) 0    0,
		conic-gradient(from 180deg at top    $thickness right $thickness, #0000 90deg, $color 0) 100% 0,
		conic-gradient(from 0deg   at bottom $thickness left  $thickness, #0000 90deg, $color 0) 0    100%,
		conic-gradient(from -90deg at bottom $thickness right $thickness, #0000 90deg, $color 0) 100% 100%,
		$background;
	background-size: $width $width;
	background-origin: border-box;
	background-repeat: no-repeat;
}

//#endregion
