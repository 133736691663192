.hover-element {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 90000;
	isolation: isolate;
	pointer-events: none;
}

*:has(> .hover-element) {
	position: relative;
}
