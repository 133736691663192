@use 'common';

html, body {
	font-family: common.$theme-font-family;
	font-feature-settings: common.$theme-font-features;
}

@supports (font-variation-settings: normal) {
	html, body {
		font-family: common.$theme-font-family-variable;
	}
}

button {
	font-family: inherit;
	font-feature-settings: inherit;
}

a {
	display: inline-block;
	color: common.$theme-link;
	cursor: pointer;
	line-height: 1.3;
	transition: all 150ms linear;
	text-decoration: none;

	&:visited:not(:hover):not(:focus):not(:active) {
		color: common.$theme-link-visited;
	}

	&:hover, &:focus, &:active {
		color: common.$theme-link-active;
	}

	&:focus-visible {
		outline: common.$theme-link-visiblefocus-outline solid 0.15em;
	}
}

p {
	line-height: 1.4;
	margin: 0 0 1em;
}

ul {
	margin: 0;
}

li {
	line-height: 1.3;
}

h2, h3, h4 {
	padding-bottom: 0.3em;
	margin: 0;
}

table {
	border: 1px solid common.$theme-table-border-outer;

	tr {
		min-height: 2em;
	}

	td,
	th {
		padding: 0.2em 0.5em;
		text-align: center;
		border: 1px solid common.$theme-table-border;

		&.noPadding {
			padding: 0;
		}
	}

	th {
		background: common.$theme-table-header-background;
		color: common.$theme-table-header-text;
	}

	tr {
		background: common.$theme-table-body-background;
		color: common.$theme-table-body-text;

		&:nth-child(2n) {
			background: common.$theme-table-body-altbackground;
		}
	}

	&.smallPadding {
		td:not(.noPadding), th:not(.noPadding) {
			padding: 0.1em 0.25em;
		}
	}
}

fieldset {
	border-color: common.$theme-fieldset-border;
}
