@use '../../../styles/common';

.spaceConfigurationScreen {
	width: min(100%, 60em);
	margin: 0 auto;

	&>* {
		margin-top: 0.5em;
	}

	.spaceConfigurationScreen-tab {
		border-top: 2px solid common.$theme-separator;
		padding: 0.5em;
	}

	.input-container {
		@include common.flex(column);
		margin-top: 1em;
		gap: 0.5em;
		width: 100%;

		ul {
			margin-top: 0;
		}
	}

	.input-line {
		@include common.flex(row);
		gap: 0.5em;
		width: 100%;
	}

	.row-half {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1em;
	}

	.message {
		font-size: 0.9em;
		margin: 0.5em 0;
	}

	.error {
		@extend .message;
		color: common.$themecolor-error;
		font-weight: 600;
	}

	.note {
		@extend .message;
		font-weight: 600;
	}
}

.backgroundSelect {
	width: min(50em, 90vw);
	max-height: calc(100vh - 10em);
	display: flex;
	flex-flow: column;
	gap: 0.5em;

	.header {
		@include common.flex(column);
		gap: 0.5em;
		max-width: 95vw;
		align-items: center;

		.header-filter {
			display: grid;
			grid-template-columns: minmax(40%, min-content) 1fr;
			grid-template-rows: auto;
			column-gap: 0.6em;
			width: 100%;

			span {
				margin: auto 0;
			}
		}

		.header-tags {
			@include common.flex(row, wrap);
			gap: 0.5em;
			width: 100%;

			button {
				width: 100%;

				span {
					float: right;
				}
			}
		}

		.input-filter {
			@include common.flex(column);
			gap: 0.5em;
			width: 100%;
			background-image: url('../../../icons/magnifier.svg');
			background-size: 1.5em;
			background-position: 0.5em 0.5em;
			background-repeat: no-repeat;
			padding: 0.5em 0.5em 0.5em 2.5em;

			ul {
				margin-top: 0;
			}
		}

		.dropdown {
			position: relative;
			display: inline-block;
			flex: 1;
			min-width: max-content;
		}

		.dropdown-button {
			text-align: left;
			padding: 0.5em;
			width: 100%;
			cursor: pointer;
			border: 1px solid common.$theme-spaceconfig-backgroundselect-filterdropdown-border;
			background: common.$theme-spaceconfig-backgroundselect-filterdropdown-background;
			color: common.$theme-spaceconfig-backgroundselect-filterdropdown-text;

			&:hover, &:active {
				&:not(:disabled) {
					background: common.$theme-spaceconfig-backgroundselect-filterdropdown-hover-background;
				}
			}

			&:focus-visible {
				outline: 0.25em solid common.$theme-spaceconfig-backgroundselect-filterdropdown-focus-outline;
			}
		}

		.dropdown-content {
			@include common.flex(column);
			display: none;
			position: absolute;
			max-height: 50vh;
			overflow-y: auto;
			border: 2px solid common.$theme-spaceconfig-backgroundselect-filterdropdown-contentborder;
			background: common.$theme-spaceconfig-backgroundselect-filterdropdown-contentbackground;
			box-shadow: 0.3em 0.3em 0.2em common.$themecolor-shadow;
			padding: 0.8em 1em;
			z-index: 1;

			a>span {
				display: inline-block;
				width: 1rem;
			}
		}

		.dropdown:hover .dropdown-content {
			display: flex;
		}
	}

	.backgrounds {
		flex: 1;
		display: grid;
		gap: 0.2em;
		padding: 0.2em;
		justify-content: space-evenly;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		overflow-x: hidden;
		overflow-y: scroll;
		background: common.$theme-spaceconfig-backgroundselect-list-background;
		color: common.$theme-spaceconfig-backgroundselect-list-text;

		@media only screen and (max-width: 719px) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		.details {
			height: 10em;

			.preview {
				@include common.center-flex;
				flex: 1;

				img {
					max-width: 100%;
					max-height: 100%;
					width: unset;
					height: unset;
					object-fit: contain;
					box-shadow: 0.3em 0.3em 0.2em common.$themecolor-shadow;
				}
			}

			.name {
				text-align: center;
			}
		}

		a:active {
			outline: none;
		}
	}

	.footer {
		max-width: 100%;
	}
}

.backgroundInfo {
	img {
		max-width: min(24em, 33vw);
		max-height: min(24em, 50vh);
		object-fit: scale-down;
	}
}

.spaceInviteCreation .input-row {
	display: grid;
	grid-template-columns: 1fr 2em 1fr;
	height: 2.1em;

	input[type=checkbox] {
		margin: auto 0;
	}
}

.spaceInvitesTable {
	&, tr, td, th {
		border: 1px solid common.$theme-separator;
		border-collapse: collapse;
	}

	th, td {
		padding: common.spacing(small);
	}

	td {
		white-space: nowrap;
	}
}

.permanentInvite {
	display: block;
	padding: 0.5em;

	.text {
		display: block;
		user-select: none;
		margin-bottom: 0.25em;
	}

	.invite {
		font-family: common.$theme-font-family-monospace;
		background: common.$theme-spaceconfig-invites-permanentlink-background;
		color: common.$theme-spaceconfig-invites-permanentlink-text;
		border-radius: 0.5em;
		padding: 0.5em 0.75em;
		display: block;
		user-select: all;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
}
