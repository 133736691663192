@use '../../styles/common';
@use 'HeaderButton';

.Header {
	@include common.flex(row, wrap);
	background: common.$theme-header-background;
	color: common.$theme-header-text;
	width: 100%;
	position: relative;

	& > div {
		@include common.flex(row, wrap);
		align-items: flex-start;
		min-height: HeaderButton.$header-button-height;

		&.rightHeader, &.leftHeader {
			align-items: stretch;
		}

		.label {
			display: none;
		}
	}

	span {
		@include common.center-flex;
		min-width: 6em;
		min-height: HeaderButton.$header-button-height;
		padding: 0 1em;
	}

	.collapsableHeaderButton {
		background: common.$theme-header-button-normal-background;
		color: common.$theme-header-button-normal-text;
		border: 0.2em solid transparent;

		&:hover {
			background: common.$theme-header-button-hover-background;
			color: common.$theme-header-button-hover-text;

			img {
				filter: common.$theme-header-button-hover-imgfilter;
			}
		}

		&:focus-visible {
			outline: 0;
			border-color: common.$theme-header-button-visiblefocus-outline;
		}
	}
}

.OverlayHeader {
	position: absolute;
	inset: 0;
	z-index: 1000;
	margin: 0;
	overflow: hidden;
	pointer-events: auto;
	background: common.$theme-header-collapsable-background;
	color: common.$theme-header-collapsable-text;
	transition: all 200ms linear;

	&.hide {
		transform: translateY(-100vh);
	}

	>.content {
		flex: 1;
		padding: 2em;
		overflow: auto;

		> hr {
			width: 100%;
		}

		> div {
			@include common.flex(column, wrap);
			align-items: stretch;
			min-height: HeaderButton.$header-button-height;
			flex: unset;

			> button, > span {
				@include common.flex(row);
				align-items: center;
				min-width: 6em;
				min-height: HeaderButton.$header-button-height;
				padding: 0 1em;
			}

			> span {
				justify-content: center;
			}

			.label {
				padding: 0 0.2em;
			}
		}
	}
}
