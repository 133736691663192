@use 'common';

//#region Inputs

input, textarea, select, select option {
	font-family: inherit;
	font-feature-settings: inherit;
}

input, textarea {
	font-size: 1em;
	padding: 0.5em;
	border: 1px solid common.$theme-input-border;
	background: common.$theme-input-normal-background;
	color: common.$theme-input-text;

	&:focus, &:active {
		outline: max(0.2em, 2px) solid common.$theme-input-focused-outline;
		background: common.$theme-input-focused-background;
	}

	&:read-only {
		background: common.$theme-input-readonly-background;
		&:focus, &:active {
			outline: 0 none;
		}
	}

	&::placeholder {
		color: common.$theme-input-placeholder-text;
		opacity: 1; /* Firefox */
	}
}

input[type=color] {
	padding: 0;
}

select {
	font-size: 1em;
	padding: 0.5em;
	border: 1px solid common.$theme-input-border;
	background: common.$theme-input-normal-background;
	color: common.$theme-input-text;
	overflow: hidden;
	white-space: pre;
	text-overflow: ellipsis;

	&:focus-visible {
		outline: max(0.2em, 2px) solid common.$theme-input-focused-outline;
	}

	&:disabled {
		background: common.$theme-input-readonly-background;
		&:focus, &:active {
			outline: 0 none;
		}
	}
}

input[type=checkbox] {
	transform: scale(1.3);
	accent-color: common.$theme-checkbox-accent;
	border-radius: 0.5em;
	filter: common.$theme-checkbox-unchecked-filter;

	&:checked {
		filter: none;
	}

	&:focus-visible {
		outline: max(0.2em, 2px) solid common.$theme-input-focused-outline;
	}

	&:disabled {
		accent-color: common.$theme-checkbox-disabled-accent;
		filter: common.$theme-checkbox-disabled-unchecked-filter;

		&:checked {
			filter: none;
		}
	}
}

//#endregion

//#region Buttons

button {
	outline: 0;
	border: 0;
	font-size: 1em;
	transition:
		all 150ms linear,
		visibility 0ms,
		outline 0ms;

	&:hover {
		cursor: pointer;
	}
}

//#endregion

