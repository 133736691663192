$z-index-menu: 100;

$spacing: (
	none: 0,
	tiny: 3px,
	small: 0.25em,
	medium: 0.5em,
	large: 1em,
	"x-large": 2em,
	"xx-large": 3em,
	"xxx-large": 5em,
);

// Helper functions for getting values in SCSS
@use "sass:map";

@function spacing($size) {
	@return map.get($spacing, $size);
}
