@use '../../styles/common';

$header-button-img-height: 1.5em;
$header-button-v-padding: 0.3em;
$header-button-height: $header-button-img-height + 2 * $header-button-v-padding;

.HeaderButton {
	background: common.$theme-header-button-normal-background;
	color: common.$theme-header-button-normal-text;
	border: 0.2em solid transparent;
	min-width: 4em;

	.icon-container {
		position: relative;
		display: inline-block;
		height: $header-button-height;
		padding: $header-button-v-padding 0.5em;

		.badge {
			position: absolute;
			display: inline-block;
			border-radius: 100%;
			bottom: 0;
			left: 60%;
			aspect-ratio: 1;
			min-height: unset;
			min-width: unset;
			width: 1.4em;
			height: 1.4em;
			font-size: 0.8em;
			line-height: 1.5;
			background: common.$theme-notification-badge-background;
			color: common.$theme-notification-badge-text;
			padding: 0 0.3em;
		}
	}

	img {
		height: $header-button-img-height;
		filter: common.$theme-header-button-normal-imgfilter;
	}

	&:hover {
		background: common.$theme-header-button-hover-background;
		color: common.$theme-header-button-hover-text;

		img {
			filter: common.$theme-header-button-hover-imgfilter;
		}
	}

	&:focus-visible {
		outline: 0;
		border-color: common.$theme-header-button-visiblefocus-outline;
	}
}
