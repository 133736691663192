@use '../styles/common';

:target, .target-highlight {
	animation: target-highlight 2s ease-in;
}

@keyframes target-highlight {
	from {
		background: common.$theme-highlight-background;
	}
	to {
		background: transparent;
	}
}
