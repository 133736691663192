@use '../../../styles/common';

fieldset.fieldset-toggle {
	border: 0.1em solid common.$theme-fieldset-border;
	border-radius: 0.2em;

	&>legend.fieldset-toggle-legend {
		display: flex;
		margin-left: 1rem;
		cursor: pointer;
		align-items: center;

		&::before {
			content: '\0025B6';
			display: inline-block;
			background-repeat: no-repeat;
			width: 1rem;
			height: 1rem;
			padding-right: 0.5rem;
		}

		&.open::before {
			content: '\0025BC';
		}
	}

	&.slim-padding {
		padding: 0.2em;
	}

	& &.slim-padding-inner {
		padding: 0.2em;
	}

	&.no-padding {
		padding: 0.2em 0 0 0;
	}

	& &.no-padding-inner {
		padding: 0.2em 0 0 0;
	}
}
