@use '../../styles/common';

.LoginTeaser {
	@include common.flex(column);
	width: min(95vw, 45em);
	min-height: min(90vh, 25em);
	background: common.$theme-auth-routerbox-background;
	color: common.$theme-auth-routerbox-text;
	border-radius: 0.2em;
	padding: 2em 2.5em 1em;
	align-self: stretch;
	box-shadow: 0 0.2em 0.4em 0 common.$themecolor-shadow, 0 0.3em 1em 0 common.$themecolor-shadow;

	.teaser-content-box {
		flex: 1;
		flex-basis: 0;
		overflow: hidden;

		.teaser-content {
			overflow: hidden;
			background: common.$theme-auth-teaser-content;
			border-top-left-radius: 0.3em;
			border-top-right-radius: 0.3em;
			box-shadow: 0 0.2em 0.4em 0 common.$themecolor-shadow, 0 0.3em 1em 0 common.$themecolor-shadow;

			.teaser-text {
				@include common.center-flex;
				padding: 0.8em;
			}

			.teaser-image {
				flex: 1;
				overflow: hidden;
				max-height: 100%;
				max-width: 100%;

				img {
					max-width: 100%;
					max-height: 100%;
					object-fit: contain;
				}
			}
		}
	}

	.teaser-navigation {
		display: inline-block;
		text-align: center;
		padding-top: 1em;
	}

	.dot {
		display: inline-block;
		background: common.$theme-auth-teaser-dot-inactive;
		border-radius: 50%;
		width: 1em;
		height: 1em;
		margin-left: 1em;

		&.active {
			background: common.$theme-auth-teaser-dot-active;
		}

		&:hover {
			background: common.$theme-auth-teaser-dot-hover;
			cursor: pointer;
		}
	}
}
