@use 'sass:map';
@use '../../../styles/common';

.Button {
	@include common.center-flex;
	display: inline-flex;
	gap: 0.5em;

	border-radius: 0.2em;
	padding: 0.8em 1.2em;
	border: 1px solid;

	outline-offset: -0.25em;

	@each $theme-name, $theme-data in common.$theme-button {
		&.#{$theme-name} {
			background: map.get($theme-data, "background");
			border-color: map.get($theme-data, "border");
			color: map.get($theme-data, "color");

			&:hover, &:active {
				&:not(:disabled) {
					background: map.get($theme-data, "hover-background");
					color: map.get($theme-data, "hover-color");
				}
			}

			&:focus-visible {
				outline: 0.25em solid map.get($theme-data, "focus-outline");
			}

			&:disabled {
				cursor: unset;
				background: map.get($theme-data, "disabled-background");
				color: map.get($theme-data, "disabled-color");
				border-color: map.get($theme-data, "disabled-border");
			}

			&.IconButton img {
				filter: map.get($theme-data, "icon-filter");
			}
		}
	}

	&.slim {
		gap: 0.3em;
		padding: 0.1em 0.3em;
	}

	&.hideDisabled:disabled {
		visibility: hidden !important;
	}

	&.IconButton {
		padding: 0.3em;

		img {
			width: 100%;
			height: 100%;
			user-select: none;
			pointer-events: none;
		}
	}

	&:not(.IconButton) {
		img {
			margin: -0.2em 0;
			height: 1.2em;
			width: 1.2em;
			pointer-events: none;
		}

		&.slim {
			img {
				margin: 0 0;
			}
		}
	}

	&.danger {
		font-weight: bold;
	}
}
