@use '../../../styles/common';

.tutorialDialogContainer {
	z-index: 90100;
}

.tutorialDialog {
	max-width: max(50vw, min(95vw, 50em));

	.finishedStep {
		color: common.$theme-tutorial-dialog-text-dim;
		font-style: italic;
	}

	.maid-container {
		overflow: hidden;
		max-height: 55vh;
		max-width: 15vw;
		padding-bottom: 0.5em;

		img {
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
		}
	}

	.tutorialNextButton {
		position: relative;

		.tutorial-highlight-overlay {
			position: absolute;
			top: -8px;
			left: -8px;
			width: calc(100% + 16px);
			height: calc(100% + 16px);
		}
	}
}

.dialog.aboveTutorial {
	z-index: 90900;
}

.tutorial-highlight-overlay {
	position: fixed;
	overflow: hidden;
	margin: 0;
	z-index: 89000; // This is positioned to be under hover elements (.hover-element), not to obscure them
	pointer-events: none;

	background: transparent;
	border: 0.3em solid common.$theme-tutorial-highlight-base-1;
	border-radius: 0.5em;

	animation: tutorial-highlight-overlay-blink 1s infinite alternate-reverse;

	&.z-aboveTutorial {
		z-index: 91000;
	}

	&.top {
		z-index: 91001;
		border: 0.3em dashed common.$theme-tutorial-highlight-top-1;
		border-style: dashed;

		animation: tutorial-highlight-overlay-blink-transparent 1s infinite alternate-reverse;
	}
}

@keyframes tutorial-highlight-overlay-blink {
	from {
		border-color: common.$theme-tutorial-highlight-base-1;
	}
	to {
		border-color: common.$theme-tutorial-highlight-base-2;
	}
}

@keyframes tutorial-highlight-overlay-blink-transparent {
	from {
		border-color: common.$theme-tutorial-highlight-top-1;
	}
	to {
		border-color: common.$theme-tutorial-highlight-top-2;
	}
}
