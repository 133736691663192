@use '../../../styles/common';

.selectionIndicator {
	position: relative;
	margin: 2px;
	border-radius: 0.5em;
	outline: 2px solid transparent;
	transition: outline-color 150ms linear;

	&.selected {
		outline-color: common.$theme-button-selected-outline;
	}

	&::before {
		content: " ";
		position: absolute;
		inset: 0px;
		outline: 2px dashed transparent;
		border-radius: 0.5em;
		transition: outline-color 150ms linear;
		pointer-events: none;
	}

	&.active:not(.selected)::before {
		outline-color: common.$theme-button-selected-outline;
	}
}
