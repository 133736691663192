@use '../../../styles/common';

.icon-hamburger {
	position: relative;
	width: 2em;
	height: 2em;
	transition: all 200ms linear;

	.icon {
		position: absolute;
		top: 45%;
		height: 0.1875em;
		width: 100%;
		transition: all 200ms linear;
		background: transparent;

		&:before{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: all 200ms linear;
			background: transparent;
			content: "";
		}

		&:after{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: all 200ms linear;
			background: transparent;
			content: "";
		}
	}

	&.state-hamburger {
		.icon {
			background: common.$theme-buttonicon-hamburger-color;

			&:before{
				background: common.$theme-buttonicon-hamburger-color;
				top: -300%;
			}

			&:after{
				background: common.$theme-buttonicon-hamburger-color;
				top: 300%;
			}
		}
	}

	&.state-cross {
		transform: rotate(-180deg);

		.icon {
			&:before{
				background: common.$theme-buttonicon-hamburger-color;
				transform: rotate(45deg);
			}

			&:after{
				background: common.$theme-buttonicon-hamburger-color;
				transform: rotate(-45deg);
			}
		}
	}
}
