@use '../../../styles/common';

.div-container {
	display: flex;

	&.direction-row {
		flex-direction: row;
		&.reverse {
			flex-direction: row-reverse;
		}
	}

	&.direction-column {
		flex-direction: column;
		&.reverse {
			flex-direction: column-reverse;
		}
	}

	&.wrap {
		flex-wrap: wrap;
	}
	&.wrap-reverse {
		flex-wrap: wrap-reverse;
	}

	&.justify-start {
		justify-content: flex-start;
	}
	&.justify-end {
		justify-content: flex-end;
	}
	&.justify-center {
		justify-content: center;
	}
	&.justify-space-between {
		justify-content: space-between;
	}
	&.justify-space-around {
		justify-content: space-around;
	}
	&.justify-space-evenly {
		justify-content: space-evenly;
	}

	&.align-start {
		align-items: flex-start;
	}
	&.align-end {
		align-items: flex-end;
	}
	&.align-center {
		align-items: center;
	}
	&.align-stretch {
		align-items: stretch;
	}

	&.wrap-align-start {
		align-content: flex-start;
	}
	&.wrap-align-end {
		align-content: flex-end;
	}
	&.wrap-align-center {
		align-content: center;
	}
	&.wrap-align-space-between {
		align-content: space-between;
	}
	&.wrap-align-space-around {
		align-content: space-around;
	}
	&.wrap-align-space-evenly {
		align-content: space-evenly;
	}
	&.wrap-align-stretch {
		align-content: stretch;
	}
}
