@use '../../styles/common';

.RootErrorBoundary {
	margin: auto;
	@include common.flex(column, nowrap);
	padding: min(2em, 3vh) 2em;
	border: 1px solid common.$theme-dialog-border;
	width: 95vw;
	max-width: 72em;
	max-height: 90vh;
	overflow: hidden;
	background: common.$theme-dialog-background;
	color: common.$theme-dialog-text;
	border-radius: 0.5em;

	& > :last-child {
		margin-bottom: 0;
	}

	h1 {
		margin-top: 0;
	}

	pre {
		position: relative;
		flex: 1;
		margin: 0 0 1em;
		border: 1px solid;
		padding: 1em;
		overflow: auto;
		background: common.$theme-errorreport-report-background;
		color: common.$theme-errorreport-report-text;
		border-radius: 0.25em;

		.button-wrapper {
			position: absolute;
			top: 0;
			right: 0;
		}

		.Button {
			position: fixed;
			border-radius: 0 0 0 0.2em;
			transform: translateX(-100%);
		}

		.report-content {
			user-select: all;
		}
	}

	.buttons {
		text-align: right;
	}
}
