@use '../../../styles/common';

.pose-presets-table {
	td {
		button {
			span, img {
				margin: auto;
			}

			@media (max-width: 768px) {
				span {
					display: none;
				}
			}
		}
	}
}
