@use '../../../styles/common';

$coloreditor-selector-size: 12.5em;

.color-editor {
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	gap: 0.65em;

	&__rect {
		position: relative;
		width: $coloreditor-selector-size;
		height: $coloreditor-selector-size;
		background: hsla(var(--hue), 100%, 50%, 1);
		margin-bottom: 0.5em;

		&__color {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background:
				linear-gradient(0deg, #000 0%, transparent 100%),
				linear-gradient(90deg, #fff 0%, transparent 100%);
		}

		&__color__pointer {
			position: absolute;
			width: 1.3em;
			height: 1.3em;
			left: calc(var(--saturation) * 100%);
			top: calc(calc(1 - var(--value)) * 100%);
			border-radius: 50%;
			border: 0.3em solid var(--rgb);
			box-shadow:
				0 0 4px 1px rgba(0, 0, 0, calc(var(--hsl-lightness) * 0.5)),
				0 2px 2px 0 rgba(0, 0, 0, calc(var(--hsl-lightness) * 0.5)),
				0 1px 5px 0 rgba(0, 0, 0, calc(var(--hsl-lightness) * 0.5)),
				0 0 4px 1px rgba(255, 255, 255, calc((1 - var(--hsl-lightness)) * 0.5)),
				0 2px 2px 0 rgba(255, 255, 255, calc((1 - var(--hsl-lightness)) * 0.5)),
				0 1px 5px 0 rgba(255, 255, 255, calc((1 - var(--hsl-lightness)) * 0.5));
			transform: translate(-50%, -50%);
			cursor: crosshair;
		}
	}

	input[type='range'] {
		-webkit-appearance: none;
		width: $coloreditor-selector-size;
		height: 1em;
		margin-bottom: 0.5em;
		padding: 0;

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			width: 1.5em;
			height: 1.5em;
			border-radius: 50%;
			background: var(--rgba);
			box-shadow:
				0 0 4px 1px rgba(0, 0, 0, calc(var(--hsl-lightness) * 0.5)),
				0 2px 2px 0 rgba(0, 0, 0, calc(var(--hsl-lightness) * 0.5)),
				0 1px 5px 0 rgba(0, 0, 0, calc(var(--hsl-lightness) * 0.5)),
				0 0 4px 1px rgba(255, 255, 255, calc((1 - var(--hsl-lightness)) * 0.5)),
				0 2px 2px 0 rgba(255, 255, 255, calc((1 - var(--hsl-lightness)) * 0.5)),
				0 1px 5px 0 rgba(255, 255, 255, calc((1 - var(--hsl-lightness)) * 0.5));
			cursor: pointer;
			position: relative;
		}

		&::-moz-range-thumb {
			width: 1.5em;
			height: 1.5em;
			border-radius: 50%;
			background: var(--rgba);
			box-shadow:
				0 0 4px 1px rgba(0, 0, 0, calc(var(--hsl-lightness) * 0.5)),
				0 2px 2px 0 rgba(0, 0, 0, calc(var(--hsl-lightness) * 0.5)),
				0 1px 5px 0 rgba(0, 0, 0, calc(var(--hsl-lightness) * 0.5)),
				0 0 4px 1px rgba(255, 255, 255, calc((1 - var(--hsl-lightness)) * 0.5)),
				0 2px 2px 0 rgba(255, 255, 255, calc((1 - var(--hsl-lightness)) * 0.5)),
				0 1px 5px 0 rgba(255, 255, 255, calc((1 - var(--hsl-lightness)) * 0.5));
			cursor: pointer;
		}
	}

	&__hue {
		background-image: linear-gradient(90deg, red, yellow, lime, cyan, blue, magenta, red) !important;
	}

	&__saturation {
		background: var(--gradient-saturation) !important;
	}

	&__value {
		background: var(--gradient-value) !important;
	}

	&__alpha {
		background: linear-gradient(90deg, transparent, var(--rgb)) !important;
	}

	&__hex {
		width: $coloreditor-selector-size;
	}
}

.color-input-button {
	border: 2px solid black; // Intentionally not themed
	border-radius: 0;
	display: flex;
	width: 4em;
	height: 2em;
	padding: 0;

	&:focus-visible {
		outline: 0.25em solid common.$theme-colorbutton-visiblefocus-outline;
	}
}
