@use '../../styles/common';

.character-select {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 3.5em;
	padding: common.spacing('x-large');
	margin: 0;

	.card {
		@include common.center-flex(column);
		height: min(min(20em, 75vh), 100vw);
		width: min(min(16em, 60vh), 80vw);
		padding: 1em;
		border-radius: 0.2em;
		background: common.$theme-characterselect-card-outerborder;
		box-shadow: common.$theme-characterselect-card-shadow;

		.border {
			@include common.center-flex(column);
			position: relative;
			height: 100%;
			width: 100%;
			padding: .5em;
			gap: 1em;
			background: common.$theme-characterselect-card-background;
			color: common.$theme-characterselect-card-text;
			border: 0.2em solid common.$theme-characterselect-card-innerborder;

			.label {
				position: absolute;
				top: .4em;
				right: .4em;
				color: common.$theme-characterselect-card-extrainfo-text;
				background: common.$theme-characterselect-card-extrainfo-background;
				padding: .1em .75em;
				border-radius: 1em;
			}

			.frame {
				background: common.$theme-characterselect-card-frame-background;
				border: max(0.1em, 2px) solid common.$theme-characterselect-card-frame-border;
				border-radius: 0.25em;
				box-shadow: common.$theme-characterselect-card-frame-shadow;
				width: 5em;
				height: 5em;
				padding: 1em;
				overflow: hidden;

				img {
					object-fit: contain;
					width: 100%;
					height: 100%;
				}
			}

			.title {
				font-size: 1.2em;
				text-align: center;
			}
		}

		&:hover {
			.border {
				background: common.$theme-characterselect-card-hover-background;
			}
		}

		&:focus-visible {
			outline: 0.25em solid common.$theme-characterselect-card-visiblefocus-outline;
		}
	}
}
