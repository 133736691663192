@use '../../../styles/common';

.spacesSearchList {
	background: common.$theme-spacesearch-gapcolor;
	padding: 2px;
	gap: 2px !important; // Overriding `Column` we use

	.spacesSearchListEntry {
		display: grid;
		width: 100%;
		align-items: center;
		background: common.$theme-normal-background;
		color: common.$theme-normal-text;
		border: 0.25em solid transparent;
		padding: 0.25em;
		overflow: hidden;

		grid-template:
			"icon extraIcons name description" 3.5em
			/ 2.8em 2em minmax(30%, max-content) 1fr;
		// grid-template might have different value for narrow screens (see lower)

		&.selected {
			background: common.$theme-active-background;
			color: common.$theme-active-text;
			.entry .offlineCount {
				color: common.$theme-active-text-dim;
			}
		}

		&:nth-child(even):not(:hover):not(.selected) {
			background: common.$theme-normal-background-secondary;
		}

		&:hover:not(.selected) {
			color: common.$theme-hover-text;
			background: common.$theme-hover-background;

			.entry .offlineCount {
				color: common.$theme-hover-text-dim;
			}
		}

		&:focus-visible {
			border-color: common.$theme-spacesearch-entry-visiblefocus-outline;
		}

		&.empty {
			&:not(:hover):not(.selected) {
				color: common.$theme-normal-text-dim;

				.entry .offlineCount {
					color: common.$theme-normal-text-dim;
				}
			}

			.entry {
				font-style: italic;
			}
		}

		&.full .userCount {
			background: common.$theme-spacesearch-entry-spacefull-highlight;
			border-radius: 0.5em;
		}

		.icon {
			grid-area: icon;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 80%;
			aspect-ratio: 1;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.icons-extra {
			grid-area: extraIcons;
			height: 80%;
			display: flex;
			flex-flow: column;
			justify-content: flex-start;

			img {
				width: 1.5em;
				height: 1.5em;
			}
		}

		.entry {
			display: flex;
			grid-area: name;
			padding: 0.5em;
			gap: 0.5em;
			vertical-align: middle;
			background: transparent;
			white-space: nowrap;
			overflow: hidden;

			.name {
				text-overflow: ellipsis;
				flex-shrink: 1;
				overflow: hidden;
			}

			.offlineCount {
				color: common.$theme-normal-text-dim;
				transition: all 150ms linear;
			}
		}

		.description-preview {
			grid-area: description;
			padding: 0.5em;
			text-align: left;
			vertical-align: middle;
			background: transparent;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.userCountWrapper {
			font-style: normal;
		}

		.userCount {
			padding: 0 0.25em;
		}
	}

	&.narrowScreen {
		.spacesSearchListEntry {
			grid-template:
				"icon extraIcons name" 1.75em
				"icon extraIcons description" 1.75em
				/ 2.8em 2em 1fr;

			&.noDescription {
				grid-template:
					"icon extraIcons name" 1.75em
					"icon extraIcons name" 1.75em
					/ 2.8em 2em 1fr;
			}
		}
	}
}

.spacesSearchSpaceDetails {
	width: 65em;
	max-width: 100%;
	display: grid;
	gap: common.spacing('medium');
	grid-template-columns: 1fr min(12em, 40vw);

	.spaceName {
		display: inline-block;
		word-break: break-word;
	}

	.preview {
		grid-column: 2;
		grid-row: 1 / 4;
		justify-self: end;
		max-height: 8em;
		max-width: 100%;
		object-fit: contain;
		box-shadow: 0.3em 0.3em 0.2em common.$themecolor-shadow;
	}

	.ownership {
		grid-column: 1;
		grid-row: 2;
	}

	.features {
		grid-column: 1;
		grid-row: 3;
	}

	.features-img {
		width: 2.5em;
		height: 2.5em;
		border: 1px solid common.$theme-separator;
		background: common.$theme-spacedetails-feature-norma-background;
		padding: 0.1em;

		&.warning {
			background: common.$theme-warningbox-background;
			color: common.$theme-warningbox-text;

			img {
				filter: brightness(0%);
			}
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.description-title {
		grid-row: 4;
	}

	.widebox {
		grid-column: 1 / 3;
		border: 0.1em solid common.$theme-separator;
		padding: 0.2em;
		background: common.$theme-spacedetails-description-background;
		color: common.$theme-spacedetails-description-text;
	}

	.title {
		grid-column: 1 / 3;
	}

	.users-list {
		grid-column: 1 / 3;
		border: 0.1em solid common.$theme-separator;
		padding: 0.2em;
		overflow-y: auto;
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));

		@media only screen and (max-width: 719px) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		div {
			display: block;
			align-items: center;
			word-wrap: break-word;

			&.offline {
				color: common.$theme-normal-text-dim;
				font-style: italic;
			}

			span {
				padding: 0 0.25em;
			}

			img {
				height: 1em;
				width: 1em;
			}
		}
	}

	.buttons {
		grid-column: 1 / 3;
	}
}

.infoBox {
	background: common.$theme-tipsbox-normal-background;
	color: common.$theme-tipsbox-normal-text;
	border-radius: 0.6em;
	border: 2px solid common.$theme-tipsbox-normal-border;
	padding: 0.4em;

	.icon {
		display: inline;
		color: common.$theme-tipsbox-icon-text;
	}

	&:hover {
		cursor: pointer;
		border: 2px solid common.$theme-tipsbox-hover-border;
	}

	&:focus-visible {
		outline: 0.25em solid common.$theme-tipsbox-visiblefocus-outline;
	}
}
