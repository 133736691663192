@use '../../styles/common';

.direct-messages {
	display: flex;
	flex-flow: row;
	background: common.$theme-dms-base-background;
	height: 100%;
	width: 100vw;

	&__list {
		width: min(11em, 25vw);
		background: common.$theme-dms-list-background;
		border-right: max(2px, 0.1em) solid common.$theme-separator;
		position: relative;

		input {
			width: 100%;
		}

		ul {
			margin: 0;
			padding: 0.65em;
			list-style-type: none;
			flex: 1;
		}

		ul > button {
			width: 100%;
			position: relative;
			border-radius: 0.3em;
			padding: 0.5em min(1em, 10%);
			margin-bottom: 0.5em;
			word-break: break-word;

			&.temp {
				font-style: italic;
			}

			.unreadIndicator {
				@include common.center-flex;
				position: absolute;
				border-radius: 50%;
				top: calc(50% - 0.7em);
				right: -0.6em;
				aspect-ratio: 1;
				min-height: unset;
				min-width: unset;
				width: 1.4em;
				height: 1.4em;
				font-size: 0.8em;
				font-weight: bold;
				background: common.$theme-dms-list-item-unread-indicator-background;
				color: common.$theme-dms-list-item-unread-indicator-text;
			}
		}
	}

	>.direct-message, >.chatArea {
		flex: 1;

		.error {
			color: common.$theme-dms-chat-error;
			font-weight: bold;
			text-align: center;
			margin: auto;
		}

		.loading {
			color: common.$theme-dms-chat-loading;
			font-weight: bold;
			text-align: center;
			margin: auto;
		}
	}

	>.direct-message-crypto-dialog {
		flex: 1;
		height: 100%;
		background: common.$theme-dms-crypto-dialog-base-background;

		.dialog-content {
			background: common.$theme-dms-crypto-dialog-background;
			color: common.$theme-dms-crypto-dialog-text;
			border-radius: 0.5em;

			hr {
				width: 100%;
			}
		}
	}
}
