@use '../../styles/common';

.profileScreen {
	display: flex;
	overflow-y: hidden;

	.tab-content {
		background: common.$theme-profile-content-background;
		color: common.$theme-profile-content-text;
	}
}

.profileView {
	border-top: max(2px, 0.1em) solid common.$theme-separator;

	.profileHeader {
		font-size: 1.8em;

		padding: 0.25em 0.5em;
		background: common.$theme-profile-header-background;
		color: common.$theme-profile-header-text;
	}

	.profileContent {
		min-height: 100%;
	}

	.labelColorMark {
		display: inline-block;
		width: 1em;
		height: 1em;
		border: 2px solid black; // Intentionally not themed
		border-radius: 50%;
	}

	.characterPreview {
		height: 100%;
		width: max(min(20em, 90vw), 33vw);
		flex-shrink: 0;
		overflow: hidden;
		border-right: max(2px, 0.1em) solid common.$theme-separator;

		&.disabled {
			display: none;
		}

		canvas {
			height: 100%;
			width: 100%;
		}
	}

	.profileDescriptionContent {
		background: common.$theme-profile-description-background;
		color: common.$theme-profile-description-text;
		padding: 0.5em;
		border: 1px solid common.$theme-profile-description-border;
		white-space: pre-wrap;
		word-wrap: break-word;
		word-break: break-word;

		a {
			text-decoration: underline;
		}
	}

	.profileEdit {
		background: common.$theme-profile-description-edit-background;
		color: common.$theme-profile-description-edit-text;
		border: 2px dashed common.$theme-profile-description-edit-border;
	}
}
