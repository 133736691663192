@use '../../../styles/common';

.roomScreen {
	overflow: hidden;
	min-width: 0 !important;

	>.room-scene {
		min-width: 0;
		min-height: 0;
		overflow: hidden;

		&.disabled {
			display: none;
		}

		canvas {
			position: absolute;
			z-index: auto;
		}
	}

	>.interactionArea {
		min-width: min(20em, 100%);
		min-height: min(10vw, 100%);
		border-left: max(2px, 0.1em) solid common.$theme-room-separator;
		background: common.$theme-room-background;
	}
}

.controls-container {
	background: common.$theme-room-controls-background;
	color: common.$theme-room-controls-text;
	border: 2px solid common.$theme-room-controls-border;
}

.space-warning {
	background: common.$theme-warningbox-background;
	color: common.$theme-warningbox-text;
	border: 1px solid common.$theme-separator;
	border-radius: 0.5em;
	padding: 0.2em 0.5em;
}

.character-info {
	margin-bottom: common.spacing('large');

	fieldset {
		display: flex;
		flex-direction: column;
		background: common.$theme-room-controls-charactercontent-background;
		border: 1px solid common.$theme-room-controls-character-border;
		border-radius: 0.5em;
		margin-bottom: 0.5em;

		>legend {
			display: flex;
			flex-flow: row wrap;
			border: 1px solid common.$theme-room-controls-character-border;
			border-radius: 0.5em;
			overflow: hidden;
			padding: 0;

			background: common.$theme-room-controls-character-border;
			gap: 1px;

			&.player {
				>span, >button {
					background: common.$theme-room-controls-player-background;
					color: common.$theme-room-controls-player-text;
				}

				>button:hover {
					background: common.$theme-room-controls-player-hover-background;
				}
			}

			.colorStrip {
				text-shadow: common.$theme-room-controls-character-colorstrip-shadow 1px 1px 1px;
			}

			>span, >button {
				font-size: 1em;
				padding: 0 0.3em;
				border: solid transparent 0.2em;
				flex-grow: 1;

				display: inline-flex;
				align-items: center;
				justify-content: center;

				background: common.$theme-room-controls-character-background;
				color: common.$theme-room-controls-character-text;

				&.safemode {
					background: common.$theme-warningbox-background;
					color: common.$theme-warningbox-text;
					padding: 0 0.5em;
				}

				&.offline {
					background: common.$theme-room-controls-offlinecharacter-background;
					padding: 0 0.5em;
					font-style: italic;
				}

				.character-icon {
					height: 1.25rem;
				}
			}

			>button:hover {
				background: common.$theme-room-controls-character-hover-background;
			}

			>button:focus-visible {
				border-color: common.$theme-room-controls-character-visiblefocus-outline;
			}
		}
	}
}

.room-construction-mode {
	.error {
		color: common.$themecolor-error;
		font-weight: bold;
		margin: auto 0;
	}
}
